import React from "react";
import Pricing from "../Pricing";
import bShape1 from "../../images/blog/Vector1.png";
import bShape2 from "../../images/blog/Vector2.png";

const PricingSection = (props) => {
  return (
    <section className="wpo-blog-section section-padding" id="blog">
      <Pricing />
      <div className="vector-1">
        <img src={bShape1} alt="" />
      </div>
      <div className="vector-2">
        <img src={bShape2} alt="" />
      </div>
    </section>
  );
};

export default PricingSection;
