import React, { useState } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import "../../sass/components/_slider-with-modal.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "none",
    border: "none",
    "border-radius": "0",
  },
};

const FlatSlider = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  return (
    <>
      <Slider {...settings}>
        {props.images.map((image, index) => (
          <div key={index} onClick={() => openModal(image)}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="modal-overlay"
        contentLabel="Image Modal"
        style={customStyles}
      >
        <div className="modal-content">
          {selectedImage && (
            <img src={selectedImage} alt="Modal" className="modal-image" />
          )}
        </div>
      </Modal>
    </>
  );
};

export default FlatSlider;
