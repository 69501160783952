import React from "react";
import { Link } from "react-router-dom";

import TwoRoomFlat from "../../images/flats/two-room-flat-2.jpg";
import ThreeRoomFlat from "../../images/flats/three-room-flat-10.jpg";

const RelatedProject = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="wpo-project-single-item">
      <div className="wpo-project-single-title">
        <h3>Află mai multe</h3>
      </div>
      <div className="wpo-project-area-s2 ">
        <div className="row align-items-center">
          <div className="wpo-project-wrap">
            <div className="sortable-gallery">
              <div className="row">
                <div className="col-lg-12">
                  <div className="project-grids gallery-container clearfix">
                    <div className="grid">
                      <div className="wpo-project-item">
                        <div className="wpo-project-img">
                          <img src={TwoRoomFlat} alt="" />
                          <div className="left-border"></div>
                          <div className="right-border"></div>
                        </div>
                        <div className="wpo-project-text">
                          <h2>
                            <Link
                              onClick={ClickHandler}
                              to={`/apartamente-2-camere`}
                            >
                              Apartamente
                            </Link>
                          </h2>
                          <span>cu 2 camere</span>
                        </div>
                      </div>
                    </div>
                    <div className="grid">
                      <div className="wpo-project-item">
                        <div className="wpo-project-img">
                          <img src={ThreeRoomFlat} alt="" />
                          <div className="left-border"></div>
                          <div className="right-border"></div>
                        </div>
                        <div className="wpo-project-text">
                          <h2>
                            <Link
                              onClick={ClickHandler}
                              to={`/apartamente-3-camere`}
                            >
                              Apartamente
                            </Link>
                          </h2>
                          <span>cu 3 camere</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RelatedProject;
