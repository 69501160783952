const Services = [
  {
    Id: "1",
    title: "Planificare impecabilă",
    description:
      "În cadrul ansamblului nostru de locuințe, planificarea este esențială pentru fiecare etapă a procesului de dezvoltare. Fiecare detaliu este atent evaluat și pus în aplicare, de la alegerea locației și a materialelor de construcție până la designul interior și amenajarea peisagistică. Ne asigurăm că fiecare aspect al proiectului este meticulos planificat și implementat pentru a te asigura că vei avea un cămin perfect, într-un mediu care îți va îndeplini toate nevoile.",
    icon: "fi flaticon-planning",
  },
  {
    Id: "2",
    title: "Design profesional",
    description:
      "Designul este un element central al ansamblului nostru de locuințe, iar expertiza noastră în acest domeniu este unul dintre pilonii pe care ne bazăm. Fiecare aspect al designului nostru este gândit pentru a oferi un spațiu elegant și funcțional. De la arhitectura exterioară și interioră până la amenajarea spațiilor verzi și a facilităților comune, fiecare detaliu este conceput pentru a crea un mediu care să se ridice la așteptările tale. Expertiza noastră în designul arhitectural și interior îți asigură că vei avea un cămin care se va ridica la așteptările tale.",
    icon: "fi flaticon-blueprint",
  },
  {
    Id: "3",
    title: "Finisaje premium",
    description:
      "Detaliile nu sunt lăsate la voia întâmplării. Ne asigurăm că fiecare locuință din complexul nostru este echipată cu finisaje premium, de la pardoseli și pereți, până la ferestre și uși. Fiecare element este selectat cu grijă și atenție pentru a oferi un aspect și o durabilitate superioare. În plus, finisajele noastre sunt concepute pentru a se integra perfect în designul modern și elegant al locuințelor noastre, aducând un plus de rafinament și eleganță fiecărei încăperi.",
    icon: "fi flaticon-double-bed",
  },
  {
    Id: "4",
    title: "Mediu eco-friendly",
    description:
      "Respectăm mediul înconjurător și ne străduim să folosim tehnologii și materiale ecologice în toate aspectele proiectului nostru. Astfel, vei avea un cămin care nu numai că este frumos și funcțional, dar și sustenabil. De la utilizarea materialelor de construcție reciclabile și a tehnologiilor eficiente energetic până la amenajarea spațiilor verzi și promovarea mobilității durabile, ne străduim să reducem impactul asupra mediului și să oferim un mediu de viață sănătos și prietenos cu mediul înconjurător.",
    icon: "fi flaticon-interior-design",
  },
];
export default Services;
