import React from "react";
import { Link } from "react-router-dom";
import VideoModal from "../ModalVideo";
import AboutUs from "../../images/about-us-homepage.jpg";

const About = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className={`wpo-about-area section-padding ${props.abClass}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={AboutUs} alt="" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-about-title">
                <span>Viziune</span>
                <h2>Pasiune, calitate și angajament pentru căminul tău</h2>
              </div>
              <h5>
                Suntem mândri să vă prezentăm noul ansamblu de locuințe, o oază
                de confort și eleganță, gândită pentru a vă oferi tot ce aveți
                nevoie pentru a trăi viețile voastre în cel mai plin și frumos
                mod posibil.
              </h5>
              <p>
                Proiectul nostru s-a născut dintr-o viziune îndrăzneață: să
                transformăm un spațiu dominat de construcții industriale într-o
                comunitate vibrantă, oferind locuințe, facilități și un mediu de
                viață superior pentru locuitorii săi.
              </p>
              <div className="btns">
                <Link
                  to="/viziune"
                  onClick={ClickHandler}
                  className="theme-btn"
                >
                  Află mai multe
                </Link>
                <ul>
                  <li className="video-holder">
                    <VideoModal />
                  </li>
                  <li className="video-text">Procesul construcției</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
