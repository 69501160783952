import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import AboutTypesOfFlats from "../../components/AboutTypesOfFlats";
import RelatedProject from "./related";
import Logo from "../../images/logo-2.png";
import Footer from "../../components/footer";
import OurApproach from "../../images/our-approach.jpg";

const TypesOfFlats = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle
        pageTitle={"Tipuri de locuință"}
        pagesub={"Tipuri de locuință"}
      />
      <div className="wpo-project-single-area section-padding">
        <AboutTypesOfFlats />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  <div className="wpo-project-single-item list-widget">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="wpo-project-single-title">
                          <h3>Strategia noastră</h3>
                        </div>
                        <ul>
                          <li>
                            Dezvoltarea unor locuințe moderne și funcționale,
                            adaptate nevoilor și preferințelor actuale ale
                            clienților.
                          </li>
                          <li>
                            Integrarea tehnologiilor de vârf pentru a asigura
                            confortul și eficiența energetică a fiecărui
                            apartament.
                          </li>
                          <li>
                            Crearea unui mediu comunitar prietenos, care
                            încurajează interacțiunea și conexiunea între
                            locuitorii ansamblului.
                          </li>
                          <li>
                            Asigurarea unei gestiuni eficiente și transparente a
                            proiectului, de la etapa de planificare până la
                            finalizarea construcției.
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <div className="wpo-project-single-item-quote">
                          <p>
                            "Locuința este mai mult decât un simplu adăpost;
                            este sanctuarul nostru personal, spațiul în care
                            trăim, iubim și creștem. Calitatea acesteia
                            influențează nu doar mediul nostru fizic, ci și
                            starea noastră de spirit și bunăstarea generală."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpo-project-single-gallery">
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="wpo-p-details-img">
                          <img src={OurApproach} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpo-project-single-item list-widget">
                    <div className="row">
                      <div className="col-12">
                        <div className="wpo-project-single-title">
                          <h3>Abordarea noastră</h3>
                        </div>
                        <ul>
                          <li>
                            Colaborarea cu experți pentru a crea spații moderne
                            și atrăgătoare în fiecare apartament.
                          </li>
                          <li>
                            Selecția atentă a materialelor și finisajelor
                            premium pentru a oferi un aspect luxos și o calitate
                            superioară.
                          </li>
                          <li>
                            Integrarea principiilor de sustenabilitate în
                            proiect, de la alegerea materialelor până la
                            implementarea sistemelor de energie eficientă.
                          </li>
                          <li>
                            Menținerea unei comunicări deschise și transparente
                            cu clienții pe parcursul întregului proces, pentru a
                            le oferi o experiență plăcută și fără griji.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <RelatedProject />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer ftClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default TypesOfFlats;
