import React from "react";
import { Link } from "react-router-dom";
import SectionTitle2 from "../SectionTitle2";
import bShape1 from "../../images/blog/Vector1.png";
import bShape2 from "../../images/blog/Vector2.png";

const Pricing = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const pricing = [
    {
      rate: "de la 97.600",
      des: "Elegant și compact",
      li1: "Finisaje premium",
      li2: "Încălzire în pardoseală",
      li3: "Centrală termică proprie",
      li4: "Uși interioare din lemn",
      li5: "1 balcon",
      li6: "Suprafață utilă de 61m²",
      li7: "Suprafață utilă totală de 65m²",
      title: "2 camere",
      link: "/apartamente-2-camere",
    },
    {
      rate: "de la 120.000",
      des: "Generos și versatil",
      li1: "Finisaje premium",
      li2: "Încălzire în pardoseală",
      li3: "Centrală termică proprie",
      li4: "Uși interioare din lemn",
      li5: "2 balcoane/terasă",
      li6: "Suprafață utilă de la 80m²",
      li7: "Suprafață utilă totală de la 88m²",
      title: "3 camere",
      link: "/apartamente-3-camere",
    },
  ];

  return (
    <section className={`wpo-pricing-section section-padding ${props.pClass}`}>
      <div className="container">
        <SectionTitle2
          subTitle={"Plan de prețuri"}
          MainTitle={"Alege planul optimal"}
          vText={"Prețuri"}
        />
        <div className="wpo-pricing-wrap">
          <div className="row">
            {pricing.map((pricing, ptem) => (
              <div className="col col-lg-6 col-md-6 col-12" key={ptem}>
                <div className="wpo-pricing-item">
                  <div className="wpo-pricing-top">
                    <div className="pricing-thumb">
                      <span>{pricing.title}</span>
                    </div>
                    <div className="wpo-pricing-text">
                      <h2>
                        {pricing.rate}€<span>+ TVA</span>
                      </h2>
                      <p>{pricing.des}</p>
                    </div>
                  </div>
                  <div className="wpo-pricing-bottom">
                    <div className="wpo-pricing-bottom-text">
                      <ul>
                        <li>{pricing.li1}</li>
                        <li>{pricing.li2}</li>
                        <li>{pricing.li3}</li>
                        <li>{pricing.li4}</li>
                        <li>{pricing.li5}</li>
                        <li>{pricing.li6}</li>
                        <li>{pricing.li7}</li>
                      </ul>
                      <Link onClick={ClickHandler} to={pricing.link}>
                        Află mai multe
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="invisible-title1">
        <h2>Prețuri</h2>
      </div>
      <div className="vector-1">
        <img src={bShape1} alt="" />
      </div>
      <div className="vector-2">
        <img src={bShape2} alt="" />
      </div>
    </section>
  );
};

export default Pricing;
