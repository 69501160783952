import pimg1 from "../images/render-2.jpg";
import pimg2 from "../images/render-3.jpg";
import pimg3 from "../images/render-4.jpg";
import pimg4 from "../images/render-5.jpg";

const Projects = [
  {
    Id: "1",
    pImg: pimg1,
  },
  {
    Id: "2",
    pImg: pimg2,
  },
  {
    Id: "3",
    pImg: pimg3,
  },
  {
    Id: "4",
    pImg: pimg4,
  },
];

export default Projects;
