import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage";
import VisionPage from "../VisionPage";
import ProgressPage from "../ProgressPage";
import TypesOfFlats from "../TypesOfFlats";
import TwoRoomFlat from "../TwoRoomFlat";
import ThreeRoomFlat from "../ThreeRoomFlat";
import ContactPage from "../ContactPage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="viziune" element={<VisionPage />} />
          <Route path="progres" element={<ProgressPage />} />
          <Route path="tipuri-de-locuinta" element={<TypesOfFlats />} />
          <Route path="apartamente-2-camere" element={<TwoRoomFlat />} />
          <Route path="apartamente-3-camere" element={<ThreeRoomFlat />} />
          <Route path="contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
