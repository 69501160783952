import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo-1.png";
import Projects from "../../api/project";
import { FACEBOOK } from "../../api/links";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const RedirectHandler = () => {
    window.open(FACEBOOK, "_blank");
  };

  return (
    <footer className={`wpo-site-footer ${props.ftClass}`}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link onClick={ClickHandler} className="logo" to="/">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <p>
                  Descoperă confortul și eleganța vieții moderne. <br />{" "}
                  Alătură-te comunității noastre și trăiește-ți visul în noul
                  nostru ansamblu de locuințe.
                </p>
              </div>
            </div>
            <div className="col col-xl-4  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact</h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>Bulevardul
                      Republicii nr. 329, Ploiești
                    </li>
                    <li>
                      <i className="fi flaticon-telephone"></i>
                      0733 369 354
                    </li>
                    <li>
                      <i className="fi flaticon-email"></i>
                      vanzari@elegancenorth.ro
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-xl-4  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget instagram">
                <div className="widget-title">
                  <h3>Descoperă mai multe</h3>
                </div>
                <ul className="d-flex">
                  {Projects.slice(0, 4).map((project, pot) => (
                    <li key={pot}>
                      <Link onClick={ClickHandler} to={"/viziune"}>
                        <img src={project.pImg} alt="" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
