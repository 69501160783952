import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Hero from "../../components/hero";
import About from "../../components/About";
import ServiceSection from "../../components/Services";
import FunFact from "../../components/FunFact";
import PricingSection from "../../components/PricingSection";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo-2.png";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"header-style-1"} />
      <Hero />
      <About />
      <ServiceSection />
      <FunFact />
      <PricingSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
