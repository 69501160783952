import React, { Fragment } from "react";

import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo-2.png";
import Footer from "../../components/footer";
import FlatSlider from "../../components/FlatSlider";

import ThreeRoomFlat1 from "../../images/flats/three-room-flat-1.jpg";
import ThreeRoomFlat2 from "../../images/flats/three-room-flat-3.jpg";
import ThreeRoomFlat3 from "../../images/flats/three-room-flat-4-7.jpg";
import ThreeRoomFlat4 from "../../images/flats/three-room-flat-10.jpg";
import ThreeRoomFlat5 from "../../images/flats/three-room-flat-11.jpg";

const images = [
  ThreeRoomFlat1,
  ThreeRoomFlat2,
  ThreeRoomFlat3,
  ThreeRoomFlat4,
  ThreeRoomFlat5,
];

const TwoRoomFlat = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle
        pageTitle={"Apartamente cu 3 camere"}
        pagesub={"Tipuri de locuință"}
      />
      <div className="wpo-project-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-8 col-12">
                      <p>
                        Aceste locuințe elegante vă invită să vă bucurați de
                        generozitatea și rafinamentul fiecărui colțișor,
                        oferindu-vă o experiență de locuire fără egal.
                      </p>
                      <p>
                        Cu o suprafață generoasă și o distribuție inteligentă a
                        spațiului, aceste apartamente reprezintă o adevărată
                        oază de relaxare și bunăstare. Cele două balcoane, dar
                        în special terasa, adaugă un plus de valoare, oferind
                        oportunități de relaxare în aer liber și conectare cu
                        mediul înconjurător, în timp ce cele două băi asigură
                        confortul și comoditatea zilnică pentru întreaga
                        familie.
                      </p>
                      <p>
                        Îmbinând designul sofisticat cu finisaje premium și
                        facilități moderne, aceste locuințe reprezintă alegerea
                        ideală pentru cei care apreciază luxul și confortul în
                        fiecare detaliu.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="wpo-project-single-content-des-right">
                        <div className="wpo-project-single-title">
                          <h4>Apartamentele 1 & 3</h4>
                        </div>
                        <ul>
                          <li>
                            Suprafață construită:
                            <span>111 m²</span>
                          </li>
                          <li>
                            Suprafață utilă:<span>80 m²</span>
                          </li>
                          <li>
                            Suprafață utilă totală: <span>88 m²</span>
                          </li>
                          <li>
                            Număr băi: <span>2</span>
                          </li>
                          <li>
                            Număr balcoane: <span>2</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="wpo-project-single-content-des-right">
                        <div className="wpo-project-single-title">
                          <h4>Apartamentele 4, 6, 7 & 9</h4>
                        </div>
                        <ul>
                          <li>
                            Suprafață construită:
                            <span>111 m²</span>
                          </li>
                          <li>
                            Suprafață utilă:<span>85.5 m²</span>
                          </li>
                          <li>
                            Suprafață utilă totală: <span>89 m²</span>
                          </li>
                          <li>
                            Număr băi: <span>2</span>
                          </li>
                          <li>
                            Număr balcoane: <span>2</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="wpo-project-single-content-des-right">
                        <div className="wpo-project-single-title">
                          <h4>Apartamentul 10</h4>
                        </div>
                        <ul>
                          <li>
                            Suprafață construită:
                            <span>162 m²</span>
                          </li>
                          <li>
                            Suprafață utilă:<span>96 m²</span>
                          </li>
                          <li>
                            Suprafață utilă totală: <span>130 m²</span>
                          </li>
                          <li>
                            Număr băi: <span>2</span>
                          </li>
                          <li>
                            Număr balcoane: <span>1</span>
                          </li>
                          <li>
                            Facilitate extra:{" "}
                            <span>
                              situat la ultimul nivel -{" "}
                              <strong>terasă de 34 m²</strong>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="wpo-project-single-content-des-right">
                        <div className="wpo-project-single-title">
                          <h4>Apartamentul 11</h4>
                        </div>
                        <ul>
                          <li>
                            Suprafață construită:
                            <span>145.5 m²</span>
                          </li>
                          <li>
                            Suprafață utilă:<span>86 m²</span>
                          </li>
                          <li>
                            Suprafață utilă totală: <span>120 m²</span>
                          </li>
                          <li>
                            Număr băi: <span>2</span>
                          </li>
                          <li>
                            Număr balcoane: <span>1</span>
                          </li>
                          <li>
                            Facilitate extra:{" "}
                            <span>
                              situat la ultimul nivel -{" "}
                              <strong>terasă de 34 m²</strong>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FlatSlider images={images} />
            </div>
          </div>
        </div>
      </div>
      <Footer ftClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default TwoRoomFlat;
