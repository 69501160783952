import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Progress from "../../api/progress";

class BuildingProcess extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className={`wpo-project-area ${this.props.pClass}`}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="wpo-project-wrap project-active owl-carousel">
              <Slider {...settings}>
                {Progress.slice(0, 4).map((progress, pot) => (
                  <div className="wpo-project-item" key={pot}>
                    <div className="wpo-project-img">
                      <img src={progress.pImg} alt="" />
                      <div className="left-border"></div>
                      <div className="right-border"></div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuildingProcess;
