import React, { Fragment } from "react";

import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo-2.png";
import Footer from "../../components/footer";
import FlatSlider from "../../components/FlatSlider";

import TwoRoomFlat1 from "../../images/flats/two-room-flat-2.jpg";
import TwoRoomFlat2 from "../../images/flats/two-room-flat-5-8.jpg";

const images = [TwoRoomFlat1, TwoRoomFlat2];

const TwoRoomFlat = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle
        pageTitle={"Apartamente cu 2 camere"}
        pagesub={"Tipuri de locuință"}
      />
      <div className="wpo-project-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <p>
                        Cu un echilibru perfect între lux și funcționalitate,
                        apartamentele cu 2 camere din ansamblul nostru
                        redefinesc standardele de trai urban. Încorporând
                        atenție la detalii și materiale de cea mai înaltă
                        calitate, aceste locuințe sunt concepute pentru a vă
                        oferi o experiență de locuire de excepție.
                      </p>
                      <p>
                        Fie că vă relaxați pe balcon, savurând momente de
                        liniște și contemplare, sau vă bucurați de facilitățile
                        moderne și finisajele premium din interior, veți găsi că
                        fiecare aspect al acestor apartamente reflectă
                        profesionalismul și devotamentul nostru pentru clienți
                      </p>
                    </div>
                    <div className="col-lg-5">
                      <div className="wpo-project-single-content-des-right">
                        <div className="wpo-project-single-title">
                          <h4>Apartamentele 2, 5 & 8</h4>
                        </div>
                        <ul>
                          <li>
                            Suprafață construită:
                            <span>79 m²</span>
                          </li>
                          <li>
                            Suprafață utilă:<span>61 m²</span>
                          </li>
                          <li>
                            Suprafață utilă totală: <span>65 m²</span>
                          </li>
                          <li>
                            Număr băi: <span>1</span>
                          </li>
                          <li>
                            Număr balcoane: <span>1</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FlatSlider images={images} />
            </div>
          </div>
        </div>
      </div>
      <Footer ftClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default TwoRoomFlat;
