import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import ServiceSidebar from "./sidebar";
import Logo from "../../images/logo-2.png";
import VisionRender from "../../images/render-1.jpg";
import Footer from "../../components/footer";

const VisionPage = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle="Viziune" pagesub={"Viziune"} />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-main-img">
                    <img src={VisionRender} alt="" />
                  </div>
                  <div className="wpo-service-single-title">
                    <h3>Misiunea noastră</h3>
                  </div>
                  <p>
                    Proiectul nostru s-a născut dintr-o viziune îndrăzneață și
                    ambițioasă: să transformăm un spațiu industrial într-o
                    comunitate modernă, vibrantă și prietenoasă, care să ofere
                    locuințe și facilități de top, îmbinate cu un mediu de viață
                    prielnic și sustenabil pentru toți locuitorii săi.
                  </p>
                  <p>
                    Suntem dedicați calității și ne străduim să aducem inovație
                    și valoare în fiecare detaliu: de la suprafetele spațioase
                    ale apartamentelor până la materialele de construcție
                    utilizate. Scopul nostru este de a oferi o experiență de
                    trai fără compromisuri. Facilităm o comunitate în care
                    fiecare locuitor să-și găsească locul, să se bucure de
                    confortul și eleganța vieții moderne și să participe la un
                    mod de viață sustenabil și responsabil.
                  </p>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Facilități și servicii</h3>
                  </div>
                  <p>
                    Bucură-te de viața la cel mai înalt nivel al confortului
                    într-o comunitate vibrantă și dinamică, pregătită să vă
                    ofere tot ceea ce aveți nevoie pentru a vă împlini visul
                    unei vieți moderne și echilibrate.
                  </p>
                  <ul>
                    <li>
                      <strong>Curte interioară: </strong>O zonă privată,
                      fermecătoare, și sigură pentru a vă relaxa și socializa.
                    </li>
                    <li>
                      <strong>Acces cu bariere automate: </strong>Garantează un
                      nivel suplimentar de siguranță și control al accesului.
                    </li>
                    <li>
                      <strong>Lift interior Schindler: </strong>
                      Asigură accesul ușor și comod la fiecare etaj.
                    </li>
                    <li>
                      <strong>Tâmplărie premium Rehau cu geam tripan: </strong>
                      Pentru izolare termică și fonică superioară, și o estetică
                      modernă și elegantă.
                    </li>
                    <li>
                      <strong>Apartamente spațioase: </strong>Pentru a oferi
                      spațiu și confort fără compromisuri.
                    </li>
                    <li>
                      <strong>Finisaje premium: </strong>De la materialele de
                      construcție la detalii, fiecare element este ales pentru
                      calitatea și durabilitatea sa.
                    </li>
                    <li>
                      <strong>Încălzire în pardoseală: </strong>O metodă
                      eficientă și confortabilă de încălzire a spațiului.
                    </li>
                    <li>
                      <strong>Spații verzi: </strong>O integrare armonioasă a
                      naturii în complexul locativ.
                    </li>
                    <li>
                      <strong>Locuri de parcare supraterane: </strong>Pentru
                      siguranță și confort în parcare.
                    </li>
                    <li>
                      <strong>
                        Platformă ecologică de colectare a deșeurilor:{" "}
                      </strong>
                      Pentru un mediu mai curat și o comunitate mai
                      responsabilă.
                    </li>
                  </ul>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Confort și eleganță</h3>
                  </div>
                  <ul>
                    <li>
                      <strong>
                        Locuințe generoase, spațioase și pline de caracter:{" "}
                      </strong>
                      Pentru a vă oferi un mediu în care să vă simțiți cu
                      adevărat acasă.
                    </li>
                    <li>
                      <strong>Poziționare strategică: </strong>
                      Acces ușor și rapid la toate facilitățile și arterele
                      principale ale orașului.
                    </li>
                    <li>
                      <strong>Oază intimă de confort și siguranță: </strong>
                      Curtea interioară și accesul securizat vă oferă un stil de
                      viață exclusiv și liniștit.
                    </li>
                    <li>
                      <strong>
                        Refugiu urban prietenos cu mediul înconjurător:{" "}
                      </strong>
                      Generozitatea spațiilor verzi și tehnologiile de ultimă
                      oră redefinește standardele de confort și sustenabilitate.
                    </li>
                    <li>
                      <strong>Comunitate conștientă și armonioasă: </strong>
                      Fiecare detaliu contribuie la crearea unei comunități care
                      respectă și îngrijește mediul înconjurător.
                    </li>
                    <li>
                      <strong>
                        Platformă ecologică de colectare a deșeurilor:{" "}
                      </strong>
                      Pentru un mediu mai curat și o comunitate mai
                      responsabilă.
                    </li>
                  </ul>
                </div>
                <div className="btns">
                  <Link
                    to="/tipuri-de-locuinta"
                    onClick={ClickHandler}
                    className="theme-btn"
                  >
                    Află mai multe despre tipurile de locuință
                  </Link>
                </div>
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </div>
      <Footer ftClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default VisionPage;
