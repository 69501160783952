import React from "react";
import VideoModal from "../ModalVideo";
import RenderImage from "../../images/render-1.jpg";

const ProcessVideo = () => {
  return (
    <section className="wpo-blog-pg-section section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="wpo-blog-content">
              <div className="post format-video">
                <div className="entry-media video-holder">
                  <img src={RenderImage} alt="" />
                  <VideoModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessVideo;
