import React from "react";
import Services from "../../api/service";
import SectionTitle from "../SectionTitle";

const ServiceSection = (props) => {
  return (
    <div className={`wpo-service-area section-padding ${props.sClass}`}>
      <div className="container">
        <SectionTitle
          subTitle={"Expertiză și Servicii Profesionale"}
          MainTitle={"Ce oferim"}
        />
        <div className="row align-items-center">
          {Services.slice(0, 4).map((service, sitem) => (
            <div className="col-12" key={sitem}>
              <div className="wpo-service-item">
                <i className={service.icon}></i>
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
