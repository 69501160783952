import React from "react";
import abimg from "../../images/about-progress-1.jpg";
import abimg2 from "../../images/about-progress-2.jpg";

const AboutProgress = () => {
  return (
    <div className="wpo-about-area-s2 section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <div className="wpo-about-img-left">
                <img src={abimg} alt="" />
                <div className="wpo-about-img-text">
                  <h2>30+</h2>
                  <p>ani de experiență</p>
                </div>
              </div>
              <div className="wpo-about-img-right">
                <img src={abimg2} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-about-title">
                <span>Descoperă avangarda în materie de locuințe</span>
                <h2>Un viitor luminos construit pas cu pas</h2>
              </div>
              <p>
                Te invit să urmărești îndeaproape evoluția ansamblului nostru
                rezidențial, un proiect ambițios care va redefini conceptul de
                refugiu urban prietenos cu mediul înconjurător.
              </p>
              <p>
                Având ca dată de start anul <strong>2024</strong> și o estimare
                de finalizare în <strong>2025</strong>, suntem dedicați creării
                unei comunități care îmbină armonios confortul modern cu
                sustenabilitatea și respectul față de mediu.
              </p>
              <p>
                Fiecare etapă a construcției este atent monitorizată și
                gestionată pentru a asigura calitatea și siguranța la standarde
                înalte. Ne angajăm să creăm spații generoase și luminoase,
                integrate într-un peisaj urban vibrant, dar și într-un cadru
                natural bogat în vegetație și spații verzi.
              </p>
              <strong>
                Prin acest proiect, ne propunem să redefinim standardele de
                confort și sustenabilitate, oferindu-vă un mediu de viață
                sănătos și prietenos cu mediul înconjurător.
              </strong>
              {/* <div className="btns">
                <Link
                  to="/progres"
                  onClick={ClickHandler}
                  className="theme-btn"
                >
                  Discover More
                </Link>
                <ul>
                  <li className="video-holder">
                    <VideoModal />
                  </li>
                  <li className="video-text">Watch Our Video</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutProgress;
