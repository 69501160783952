import React from "react";
import ins1 from "../../images/render-2.jpg";
import ins2 from "../../images/render-3.jpg";
import ins3 from "../../images/render-4.jpg";
import ins4 from "../../images/render-5.jpg";

const ServiceSidebar = () => {
  return (
    <div className="col-lg-4 col-12">
      <div className="blog-sidebar">
        <div className="widget wpo-instagram-widget">
          <div className="widget-title">
            <h3>Prezentarea complexului</h3>
          </div>
          <ul className="d-flex">
            <li>
              <img src={ins1} alt="" />
            </li>
            <li>
              <img src={ins2} alt="" />
            </li>
            <li>
              <img src={ins3} alt="" />
            </li>
            <li>
              <img src={ins4} alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceSidebar;
