import React from "react";
import abimg from "../../images/types-of-flats.png";
import abimg2 from "../../images/about-shape.jpg";

const AboutTypesOfFlats = () => {
  return (
    <div className="wpo-about-area-s3 section-padding pt-0">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={abimg} alt="" />
              <div className="wpo-about-img-text">
                <h2>2</h2>
                <p>tipuri de locuință</p>
                <div className="about-shape">
                  <img src={abimg2} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <h1>Eleganță</h1>
              <div className="wpo-about-title">
                <h2>Tipuri de locuință</h2>
              </div>
              <p>
                Orice tip de apartament din ansamblul nostru oferă o combinație
                perfectă între lux și utilitate, într-un mediu care vă invită să
                vă simțiți acasă în fiecare zi.
              </p>
              <ul className="ab-list">
                <li>
                  Cu <strong>tâmplărie Rehau și geam tripan</strong>,
                  apartamentele asigură o izolare excelentă, creând un refugiu
                  liniștit în mijlocul agitației orașului.
                </li>
                <li>
                  <strong>Încălzirea în pardoseală</strong> îți garantează
                  confortul în orice moment al anului, iar{" "}
                  <strong>finisajele premium</strong> conferă un aspect elegant
                  și rafinat întregului apartament.
                </li>
                <li>
                  <strong>Ușile interioare din lemn</strong> adaugă un element
                  de calitate și durabilitate, completând designul modern al
                  locuinței.
                </li>
                <li>
                  Cu <strong>liftul interior Schindler</strong>, accesul în
                  apartament este ușor și rapid, facilitând fiecare aspect al
                  vieții de zi cu zi.
                </li>
                <li>
                  Balconul adaugă o notă de prospețime și lumină naturală,
                  oferind un spațiu perfect pentru a vă relaxa sau a vă bucura
                  de un mic dejun în aer liber.
                </li>
              </ul>
              <strong>
                Descoperă combinația perfectă între confort și eleganță în acest
                apartament fermecător, care vă va încânta și îmbogăți fiecare
                moment petrecut în el.
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTypesOfFlats;
