import pimg1 from "../images/progress/progress-1.jpg";
import pimg2 from "../images/progress/progress-2.jpg";
import pimg3 from "../images/progress/progress-3.jpg";
import pimg4 from "../images/progress/progress-4.jpg";
import pimg5 from "../images/progress/progress-5.jpg";

const Projects = [
  {
    Id: "1",
    pImg: pimg1,
  },
  {
    Id: "2",
    pImg: pimg2,
  },
  {
    Id: "3",
    pImg: pimg3,
  },
  {
    Id: "4",
    pImg: pimg4,
  },
  {
    Id: "5",
    pImg: pimg5,
  },
];

export default Projects;
