import React from "react";

const FunFact = (props) => {
  const funfact = [
    {
      title: "30+",
      subTitle: "ani de experiență",
    },
    // {
    //   title: "32",
    //   subTitle: "Expert Specialist",
    // },
    {
      title: "500+",
      subTitle: "Proiecte de succes",
    },
    {
      title: "500+",
      subTitle: "Clienți mulțumiți",
    },
  ];

  return (
    <section className={`wpo-fun-fact-section pb-120 ${props.fnClass}`}>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="wpo-fun-fact-grids clearfix">
              {funfact.map((funfact, fitem) => (
                <div className="grid" key={fitem}>
                  <div className="info">
                    <h3>{funfact.title}</h3>
                    <p>{funfact.subTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
